<template>
    <b-overlay :show="showLoader">
        <b-card class="regular-billing mb-0">
            <h1 class="text-secondary font-title">Rezervni sklad</h1>
            <b-row class="m-0 mt-2" align-v="center">
                <b-col cols="12" md="6" xl="3" class="mt-1 mt-xl-0 px-0 px-md-1">
                    <h4 class="mb-0 pl-1 pl-lg-0 font">Poišči razdelilinik:</h4>
                </b-col>
                <b-col cols="12" md="6" xl="3" class="mt-1 mt-xl-0 px-0 px-md-1">
                    <Select v-model="filter.date" :options="dates" placeholder="Obdobje" @change="loadReserveFund"/>
                </b-col>
                <b-col cols="12" md="6" xl="4" class="mt-1 mt-xl-0 px-0 px-md-1">
                    <Select v-model="activeUnit" :options="units" label="naziv" placeholder="Enota" @change="loadReserveFund" 
                            :customDropdownLabelFn="customLabelFunction"
                            :customSelectedLabelFn="customLabelFunction"/>
                </b-col>
                <b-col cols="12" md="6" xl="2" class="mt-1 mt-xl-0 px-0 px-md-1">
                    <rounded-button class="w-100">IŠČI <fa icon="search"/></rounded-button>
                </b-col>
            </b-row>
            <div class="p-1 mt-2" style="background-color: #72A5D8;">
                <h4 class="m-0 text-white font">Razdelilnik stroškov: <template v-if="activeUnit">{{ activeUnit.inventarna_stevilka }} - {{ filter.date ? dayjs(filter.date).format('MMMM YYYY') : '/' }}</template></h4>
            </div>
            <div class="mt-2">
                <b-row v-if="activeUnit" class="mt-2 data">
                    <b-col cols="12" md="6" lg="4" class="mt-1 border-separator pb-1 pb-md-0">
                        <div class="font-bottom"> <div class="text-bold d-inline">Št. enote:</div> {{activeUnit.sifra}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">Inv. št:</div> {{activeUnit.inventarna_stevilka || '/'}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">GURS št.</div> stavbe: {{activeUnit.stev_stavbe}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">GURS št.</div> dela stavbe: {{activeUnit.stev_dela_stavbe}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">GURS št.</div> enote: {{activeUnit.zaporedna}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">KS:</div> {{activeUnit.katastrska_obcina}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">Parcela:</div> {{activeUnit.parcela}}</div>
                    </b-col>
                    <b-col cols="12" md="6" lg="4" class="mt-1 border-separator pb-1 pb-md-0">
                        <div class="font-bottom" v-if="data && data.obracun_leto && data.obracun_mesec"> <div class="text-bold d-inline">Leto/mesec:</div> {{data.obracun_leto}}/{{data.obracun_mesec}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">Solastniški delež:</div> {{activeUnit.solastniski_delez.toFixed(2)}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">Velikost neto:</div> {{activeUnit.velikost_neto.toFixed(2)}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">Ogrevalna površina:</div> {{activeUnit.velikost_kub.toFixed(2)}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">Št. oseb:</div> {{activeUnit.stev_oseb}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">Št. živali:</div> {{activeUnit.stev_zivali}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">Št. parkirišč:</div> {{activeUnit.stev_parkirisc}}</div>
                    </b-col>
                    <b-col cols="12" md="6" lg="4" class="mt-1">
                        <div class="font-bottom"> <div class="text-bold d-inline">Velik. garaže:</div> {{activeUnit.velikost_garaze}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">Vrsta enote:</div> {{activeUnit.vrsta_enote}}</div>
                        <div class="font-bottom"> <div class="text-bold d-inline">Vrsta prostora:</div> {{activeUnit.vrsta_prostora}}</div>
                        <div style="height: 50px;"></div>
                        <!--                        <div class="pr-1"  style="position: absolute; bottom: 0px; right: 0px;">
                            &lt;!&ndash; :disabled="!filter.date" &ndash;&gt;
                            <rounded-button class="mt-3" @click="downloadZipFakture">PRENESI FAKTURE <fa class="ml-1" icon="download"/></rounded-button>
                        </div>-->
                    </b-col>
                </b-row>
            </div>
            <!-- <b-table class="first-table top-table mt-3" small responsive :items="items" :fields="fields">
                <template slot="top-row">
                    <td colspan="13" class="text-secondary font">REZERVNI SKLAD</td>
                </template>
                <template #custom-foot>
                    <td colspan="10">Skupaj rezervni sklad</td>
                    <td>59,9253</td>
                </template>
            </b-table> -->

            <!-- <h3 class="font mt-4">Rekapitulacija skladov</h3>
        <b-row class="mt-1">
            <b-col cols="12" md="12" lg="9">
                <b-table class="last-table mb-0" responsive :items="itemsTwo" :fields="fieldsTwo">
                </b-table>
            </b-col>
            <b-col cols="12" md="12" lg="3" class="mt-1 mt-lg-0 d-flex align-items-lg-end justify-content-lg-end">
            </b-col>
        </b-row> -->

        </b-card>
        <b-row>
            <b-col class="px-2 px-md-1">
                <rounded-button :disabled="disableButton" block @click="downloadRazdelilnik">
                    PRENESI PDF RAZDELILNIK 
                    <fa class="ml-1" icon="download"/>
                    <b-spinner class="ml-1" style="height: 15px; width: 15px;" v-if="pdfRazdelilnik" />
                </rounded-button>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
    import {BRow, BCol,  BCard, BOverlay, BSpinner} from 'bootstrap-vue'
    import Select from '../../Components/Select'
    import RoundedButton from '@/views/Components/RoundedButton.vue'

    export default {
        components: {
            BRow, BCol, Select, BCard, BOverlay, RoundedButton, BSpinner
        },
        data() {
            return {
                pdfRazdelilnik: false,
                filter: { date: '', manager: 'STANINVEST', unit: ''},
                selected: null,
                units: [],
                activeUnit: null,
                series: [8.18, 8.18, 8.18],
                colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#3F51B5', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B', '#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A', '#2B908F', '#F9A3A4', '#90EE7E', '#FA4443', '#69D2E7'],
                chartOptions: {
                    labels: ['Team A', 'Team B', 'Team C']
                },
                dates: [],
                fields: [
                    { key: 'a', label: 'Vrsta stroška', sortable: false, class: 'text-left' },
                    { key: 'c', label: 'Celotni znesek', sortable: false, class: 'text-left' },
                    { key: 'd', label: 'Ključ delitve', sortable: false, class: 'text-left' },
                    { key: 'e', label: 'Količina', sortable: false, class: 'text-left' },
                    { key: 'f', label: 'Skupna količina', sortable: false, class: 'text-left' },
                    { key: 'g', label: 'Faktor', sortable: false, class: 'text-left' },
                    { key: 'h', label: 'Cena za razdelitve', sortable: false, class: 'text-left' },
                    { key: 'i', label: 'Osnova za DDV', sortable: false, class: 'text-left' },
                    { key: 'j', label: 'Stopnja DDV', sortable: false, class: 'text-left' },
                    { key: 'k', label: 'Znesek DDV', sortable: false, class: 'text-left' },
                    { key: 'l', label: 'Skupaj z DDV', sortable: false, class: 'text-left' }
                ],
                items: [{a: 'Storitev upravljanja', b: '(1U2) 004', c: '7,84 €', d: 'Posamezna enota', e: '1', f: '1,00', g: '', h: '7,8395 €', i: '6,4259 €', j: '22,00 %', k: '1,4137 €', l: '7,8395 €'}, {a: 'Storitev upravljanja', b: '(1U2) 004', c: '7,84 €', d: 'Posamezna enota', e: '1', f: '1,00', g: '', h: '7,8395 €', i: '6,4259 €', j: '22,00 %', k: '1,4137 €', l: '7,8395 €'}],
                fieldsTwo: [
                    { key: 'a', label: 'Naziv', sortable: false, class: 'text-left' },
                    { key: 'b', label: 'Obdobje', sortable: false, class: 'text-left' },
                    { key: 'c', label: 'Skupaj', sortable: false, class: 'text-left' }
                ],
                itemsTwo: [{a: 'Stanje RS dne', b: '5.7.2021', c: '14,4137'}, {a: 'Vplačila (prilivi RS)', b: '1.1.2001 - 2.7.2021', c: '14,4137'}],
                showLoader: false            
            }
        },
        methods: {
            customLabelFunction(unit) {
                return `${unit.naziv} / ${unit.zaporedna}, šifra: ${unit.sifra} (${unit.vrsta === 'L' ? 'Lastnik' : 'Uporabnik'})`
            },
            async loadData() {
                this.showLoader = true
                this.$scrollToTop()
                try {
                    await this.loadDates()
                    await this.loadUnits()
                    await this.loadReserveFund()
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async loadDates() {
                const res = await this.$http.get('/api/iiportal-user/v1/e_manager/date/rezervni_sklad')
                this.dates = res.data

                if (this.dates.length > 0) this.filter.date = res.data[0]
            },
            async  loadUnits() {
                const res = await this.$http.get('/api/iiportal-user/v1/e_manager/enota')
                this.units = res.data

                this.units.sort((a, b) => { 
                    if ((a.naziv === b.naziv && a.zaporedna < b.zaporedna) || (a.naziv < b.naziv)) return -1
                    else if (a.naziv > b.naziv) return 1
                    else return 0
                })
                
                if (this.units && this.units.length) {
                    this.activeUnit = this.units[0]
                }
            },
            async loadReserveFund() {
                if (!this.activeUnit || !this.filter.date) return 
                if (!this.showLoader) this.showLoader = true
                // partner_sifra s stroškovnimi grafi
                // 1003807
                // 800587
                // 910320 - naredi 270 duplikatov
                try {
                    const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/razdelilnik/rezervni_sklad/${this.filter.date}/${this.activeUnit.sifra}`)
                    this.data = res.data 
                } catch (error) {
                    this.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
    
                } finally {
                    this.showLoader = false
                }
            },
            downloadRazdelilnik() {
                const thisIns = this
                this.pdfRazdelilnik = true
                this.$http({
                    url: `/api/iiportal-user/v1/attachements/razdelilnik/redni_obracun/${this.data.sklic}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `Razdelilnik stroškov ${this.data.enota_sifra} ${this.data.obracun_leto}-${this.data.obracun_mesec}.pdf`) //or any other extension
                    document.body.appendChild(link)
                    link.click()
                })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju pdf je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        thisIns.pdfRazdelilnik = false
                    })
            },
            expandAdditionalInfo(row) {
                row._showDetails = !row._showDetails
            }
        },
        computed: {
            disableButton() {
                //disables button if no data is present for the given filter
                if (this.activeUnit) {
                    return false
                }
                return true
            }
        },
        async mounted() {
            await this.loadData()
        }

        
    }
</script>

<style>
    .regular-billing .button-faktura{
        background: #72A5D8 !important;
    }

    .regular-billing .text-bold{
        font-weight: bold !important;
        color: black;
        font-size: 1.2rem !important;
    }

    .regular-billing .font-bottom{
        color: #515050;
        font-size: 1.1rem !important;
    }
    .regular-billing .button-search{
        border: none;
    }

    .button-pdf-billing:hover{
        box-shadow: none !important;
    }

    .button-pdf-billing{
        border: none;
        background: #72A5D8 !important;
    }
    .regular-billing .font-title {
        color: #72A5D8 !important;
        font-weight: bold;
    }
    .regular-billing .font {
        font-weight: bold;
    }

    .regular-billing .dropdown .btn, .regular-billing .dropdown .dropdown-toggle {
        border-radius: 0;
        border: none;
    }
    .regular-billing .dropdown .btn {
        background-color: #E8E7E6 !important;
        color:black !important;
        box-shadow: none !important;
    }
    .regular-billing .dropdown .dropdown-toggle {
        background-color: #009FD4 !important;
        color:black;
    }

    .regular-billing .table th, .card-traffic .table tr {
        background-color: #dbe9f5 !important;
        border-top: none;
        border-bottom: none;
        text-transform: none;
        padding-top: 10px !important;
        padding-bottom: 20px !important
    }

    .regular-billing .table thead tr {
        border-bottom: 2px solid #72a5d8;
        border-right: 30px solid #dbe9f5 !important;
        border-left: 30px solid #dbe9f5 !important;
        color: #72a5d8 !important;
        font-weight: bold;
    }

    .regular-billing .table tbody tr {
        background-color: #eaf3f9 !important;
        border-top: 1px solid #72a5d87e;
        border-right: 30px solid #eaf3f9;
        border-left: 30px solid #eaf3f9;
    }

    .regular-billing .table tfoot {
        background-color: #eaf3f9 !important;
        border-top: 2px solid #72a5d87e;
        border-right: 30px solid #eaf3f9;
        border-left: 30px solid #eaf3f9;
        border-bottom: 20px solid #eaf3f9;
        font-weight: bold;
    }
    .regular-billing .border-separator {
    border-bottom: 1px solid #bfbfbf!important;
    border-right: none !important;
    padding-bottom: 1em;
}

@media only screen and (min-width: 720px) {
    .regular-billing .border-separator{
        border-bottom: none !important;
        border-right: 1px solid #bfbfbf!important;
    }
}
.download-pdf-button {
    font-weight: bolder !important;
    font-size: 1.25em;
}
</style>
